export const environment = {
    production: false,
    debug     : true,
    api       : {
        graphql: 'https://orion-dev-api.beopen.cloud/graphql',
        rest   : 'https://orion-dev-api.beopen.cloud',
    },
    lang: {
        base    : 'es',
        fallback: 'es',
        langs   : ['es','en'],
    },
    oAuth: {
        applicationCode  : 'orion',
        applicationSecret: 'eyJSb2xlIjoiQW$RtaW4iLCJ.Jc3N1ZXIiOiJJc3N1Z/XIiLCJVc2VybmFtZ',
    },
    appearance: {
        theme : 'theme-default',
        layout: 'classy',
    }
};
